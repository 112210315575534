* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: Vodafone, Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: 'Vodafone';
  src: url('./fonts/Vodafone.eot');
  src: local('Vodafone'), url('./fonts/Vodafone.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Vodafone.woff2') format('woff2'), url('./fonts/Vodafone.woff') format('woff'),
    url('./fonts/Vodafone.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vodafone';
  src: url('./fonts/Vodafone-Bold.eot');
  src: local('Vodafone-Bold'), url('./fonts/Vodafone-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Vodafone-Bold.woff2') format('woff2'), url('./fonts/Vodafone-Bold.woff') format('woff'),
    url('./fonts/Vodafone-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Vodafone';
  src: url('./fonts/Vodafone-Light.eot');
  src: local('Vodafone-Light'), url('./fonts/Vodafone-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Vodafone-Light.woff2') format('woff2'), url('./fonts/Vodafone-Light.woff') format('woff'),
    url('./fonts/Vodafone-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Vodafone';
  src: url('./fonts/Vodafone-ExtraBold.eot');
  src: local('Vodafone-ExtraBold'), url('./fonts/Vodafone-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Vodafone-ExtraBold.woff2') format('woff2'), url('./fonts/Vodafone-ExtraBold.woff') format('woff'),
    url('./fonts/Vodafone-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url('./fonts/fontello.eot?92864775');
  src: url('./fonts/fontello.eot?92864775#iefix') format('embedded-opentype'),
    url('./fonts/fontello.woff2?92864775') format('woff2'), url('./fonts/fontello.woff?92864775') format('woff'),
    url('./fonts/fontello.ttf?92864775') format('truetype'), url('./fonts/fontello.svg?92864775#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
